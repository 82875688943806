#desktop{
    display:none;
}
#tablet{
    display:none;
}
#bigtablet{
    display:none;
}

#artwork:hover {
    opacity: 0.8;
}

@media only screen and (min-width:1010px){
    #mobile{
        display:none;
    } 
    #tablet{
        display:inline;
    }
    #desktop{
        display:none;
    }
    #bigtablet{
        display:none;
    }
}

@media only screen and (min-width:1480px){
    #mobile{
        display:none;
    } 
    #tablet{
        display:none;
    }
    #desktop{
        display:none;
    }
    #bigtablet{
        display:inline;
    }
}


@media only screen and (min-width:1800px){
    #mobile{
        display:none;
    } 
    #tablet{
        display:none;
    }
    #desktop{
        display:inline;
    }
    #bigtablet{
        display:none;
    }
    
}