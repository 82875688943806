
@media only screen and (max-width:500px){
    #yt{
        padding-top:5px;
    }
    #s{
        padding-top:5px;
    }
    #f{
        padding-top:5px;
    }
    #i{
        padding-top:5px;
    }
    #t{
        padding-top:5px;
    }
}